import React from "react";
import * as Icon from "components/Icon";
import { ItemDisplay } from "components/ItemDisplay";
import { TextVariant } from "components/Text";
import "./DetailCard.scss";

/** Common metadata to display in DetailCard's detailSection */

/** Number of bytes in the dataset */
export function DetailCardBytesMetadata({ bytes }: { bytes: number | null }) {
    return <ItemDisplay.Bytes bytes={bytes} icon={<Icon.Database />} variant={TextVariant.SMALL} />;
}

/** Creation date */
export function DetailCardDateMetadata({ date }: { date: Date | number }) {
    return <ItemDisplay.Date date={date} variant={TextVariant.SMALL} />;
}

/* TODO: add FileTypeMetadata for MPPL cards? */

/** Is this card visible on the project homepage? */
export function DetailCardVisibilityMetadata({ visible }: { visible: boolean }) {
    return visible ? (
        <Icon.Eye size={16}>Visible on project homepage</Icon.Eye>
    ) : (
        <Icon.EyeOff size={16}>Hidden on project homepage</Icon.EyeOff>
    );
}
