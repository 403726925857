import { TEXT_AREA_WRAPPER_CLASS } from "components/TextInput";
import { TEXT_INPUT_WRAPPER_CLASS } from "components/TextInput/TextField";
import { TEXT_INPUT_LABEL_CLASS } from "components/util/InputWrapper";
import { Memo, useBrandedCallback } from "hooks/useBranded";
import { EventFilter } from "hooks/useFilteredEventHandler";
import { RefObject, useMemo } from "react";
import { combineSelectors } from "util/css";

export const BUTTON_ROLE_SELECTOR = `[role="button"][tabindex]:not([tabindex="-1"])`;

/**
 * A list of interactive element selectors that are likely to be focusable AND have
 * onClick/onKeyDown/etc. handlers.
 */
export const FOCUSABLE_CLICKABLE_ELEMENT_SELECTORS = [
    "a[href]",
    'a[tabindex]:not([tabindex="-1"])',
    "area[href]",
    "button",
    "input",
    "select",
    "textarea",
    "[contenteditable=true]",
    `[role="link"][tabindex]:not([tabindex="-1"])`,
    BUTTON_ROLE_SELECTOR,
];

/**
 * A list of interactive element selectors that are likely to be tab-focusable.
 */
export const FOCUSABLE_ELEMENT_SELECTORS = [
    ...FOCUSABLE_CLICKABLE_ELEMENT_SELECTORS,
    `[tabindex]:not([tabindex="-1"])`,
];

/**
 * This is a list of different types of interactive elements that likely have
 * onClick/onKeyDown/etc. handlers, whose events we will likely want to ignore.
 *
 * These are the default selectors for {@link useCssSelectorFilter}
 */
export const CLICKABLE_ELEMENT_SELECTORS = [
    ...FOCUSABLE_CLICKABLE_ELEMENT_SELECTORS,
    // TextField and TextArea wrapper elements are included so that clicks on elements in
    // the input overlay are ignored.
    `.${TEXT_INPUT_WRAPPER_CLASS}`,
    `.${TEXT_AREA_WRAPPER_CLASS}`,
    `.${TEXT_INPUT_LABEL_CLASS}`,
    ".bb-selector__label",
    ".bb-toggle__label",
];

export function useCssSelectorFilter<E extends { target: unknown }>(
    element: RefObject<Element>,
    excludedSelectors: string[] = CLICKABLE_ELEMENT_SELECTORS,
): Memo<EventFilter<E>> {
    const excludedSelector = useMemo(
        () => combineSelectors(excludedSelectors),
        // We want to be able to pass inline arrays of selectors. Without joining the array
        // this would recompute on every render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [excludedSelectors.join()],
    );
    return useBrandedCallback(
        (event) => {
            return (
                event.target instanceof Element
                && !!element.current?.contains(event.target)
                && event.target.matches(excludedSelector)
                && event.target !== element.current
            );
        },
        [element, excludedSelector],
    );
}
