import { useActive } from "hooks/useActive";
import {
    BUTTON_ROLE_SELECTOR,
    CLICKABLE_ELEMENT_SELECTORS,
    useCssSelectorFilter,
} from "hooks/useCssSelectorFilter";
import { Memo, useBrandedCallback, useBrandedMemo } from "hooks/useBranded";
import { useEventListener } from "hooks/useEventListener";
import { EventFilter, useFilteredEventHandler } from "hooks/useFilteredEventHandler";
import { useHold, UseHoldResult } from "hooks/useHold";
import { useHover } from "hooks/useHover";
import {
    Dispatch,
    FocusEventHandler,
    KeyboardEvent,
    KeyboardEventHandler,
    MouseEvent,
    MouseEventHandler,
    RefObject,
    SetStateAction,
    UIEvent,
    UIEventHandler,
    useEffect,
    useRef,
} from "react";

/**
 * These props collect some commonly used attributes for button-like elements. Not all are
 * necessary, but are included here to inform what properties you might supply to your button-like
 * element.
 */
export interface UseButtonRoleProps<E extends Element> {
    /**
     * Whether to enable the button-like properties. If set to false, the returned
     * buttonProps will be empty, disabling any button-like qualities. This is useful
     * if your element should only be treated like a button in some cases. In some of these
     * cases, you may still want to use the aria-label you provide to this function (and other
     * properties) as properties for your component. In such cases, make sure to pass these
     * properties to your component where appropriate.
     *
     * Defaults to true.
     */
    enabled?: boolean;
    /**
     * The aria-label to apply to your button-like element. This or aria-labelledby should almost
     * always be provided.
     */
    "aria-label"?: string;
    /**
     * The id of the element that labels your button. This or aria-label should almost always be
     * provided.
     */
    "aria-labelledby"?: string;
    /**
     * The value for aria-disabled on your element. If set to true, the supplied handlers will not
     * fire.
     *
     * Defaults to false.
     */
    "aria-disabled"?: boolean;
    /**
     * If specified, this property indicates that your button-like element is a toggle button.
     * A toggle button can be in one of three states: on (true), off (false), or mixed. In general,
     * you should not have to specify this property.
     *
     * Defaults to undefined.
     */
    "aria-pressed"?: boolean | "mixed";
    /**
     * The handler to call when your element is clicked. Note that this handler will be wrapped
     * using a number of other hooks, and combined with {@link onClickHold} (if provided) into
     * onMouseDown, onMouseUp, onKeyDown, and onKeyUp. The
     * splitting of this simple click into mousedown and mouseup is to facilitate the functionality
     * of the {@link onClickHold} handler, though it is split into mousedown and mouseup handlers
     * regardless of whether an {@link onClickHold} is provided.
     *
     * As such, you should provide the returned handlers to your element, *not* the onClick
     * you pass here. Not doing so will cause your handlers not to function properly.
     *
     * Ensuring the click is fired from the left mouse button, or in the case of a keypress,
     * that it's fired from the space or enter key, is already handled within the hook, so your
     * provided handler need not check these (just as if you were passing your handler to a
     * native button).
     */
    onClick?: Memo<UIEventHandler<E>>;
    /**
     * The handler to call when your element is clicked and held for {@link holdDelay} ms. Note
     * that this handler will be wrapped using a number of other hooks, and combined with
     * {@link onClick} (if provided) into onMouseDown, onMouseUp,
     * onKeyDown, and onKeyUp. The splitting of this handler into mousedown and
     * mouseup is to facilitate the functionality of the hold behavior, though it is split into
     * mousedown and mouseup handlers regardless of whether this handler is provided.
     *
     * As such, you should provide the returned handlers to your element, *not* the onClick
     * you pass here. Not doing so will cause your handlers not to function properly.
     *
     * Ensuring the click is fired from the left mouse button, or in the case of a keypress,
     * that it's fired from the space or enter key, is already handled within the hook, so your
     * provided handler need not check these (just as if you were passing your handler to a
     * native button).
     */
    onClickHold?: Memo<UIEventHandler<E>>;
    /**
     * The amount of time to wait before triggering the `onClickHold` handler after clicking and
     * holding on the element.
     *
     * Defaults to {@link HOLD_DELAY}.
     */
    holdDelay?: number;
    /**
     * An array of selectors (and their children) to exclude from click/keypress event handling
     * for the provided onClick handler.
     *
     * Defaults to {@link CLICKABLE_ELEMENT_SELECTORS}.
     */
    excludedSelectors?: string[];
}

interface UseButtonRoleResult<E extends Element> {
    active: boolean;
    setActive: Dispatch<SetStateAction<boolean>>;
    hover: boolean;
    setHover: Dispatch<SetStateAction<boolean>>;
    buttonProps:
        | Memo<{
              onMouseDown: Memo<MouseEventHandler<E>>;
              onMouseUp: Memo<MouseEventHandler<E>>;
              onMouseLeave: Memo<MouseEventHandler<E>>;
              onMouseOver: Memo<MouseEventHandler<E>>;
              onKeyDown: Memo<KeyboardEventHandler<E>>;
              onKeyUp: Memo<KeyboardEventHandler<E>>;
              onBlur: Memo<FocusEventHandler<E>>;
              role: "button";
              tabIndex: 0;
              "aria-label"?: string;
              "aria-disabled": boolean;
              "aria-pressed"?: boolean | "mixed";
          }>
        | undefined;
}

// Don't include elements with the `button` role, since that can cause issues with triggering
// clicks on the target element of the `useButtonRole` hook.
const CLICKABLE_ELEMENT_SELECTORS_EXCLUDING_BUTTON_ROLE = CLICKABLE_ELEMENT_SELECTORS.filter(
    (selector) => selector !== BUTTON_ROLE_SELECTOR,
);

/**
 * This hook combines a number of other useful hooks to make setting up an element with
 * the "button" role to behave more like a native button. It returns some basic boilerplate
 * for your button-like element, and also wraps the provided onClick and onClickHold handlers
 * using {@link useFilteredEventHandler} and transforms them into various separate handlers
 * to make your element behave like a button while ignoring other interactive elements within it.
 *
 * Additionally, it sets up simulated active and hover state in case the native :active and :hover
 * CSS states won't work for you, in case you have interactive elements within your button-like
 * element that you don't want to trigger the active and hover states. This is accomplished
 * using the {@link useActive} and {@link useHover} hooks.
 *
 * Note that this hook only sets up accessibility for your element, and will not apply any
 * additional styles to it. If that is necessary, it will have to be handled externally in addition
 * to the use of this hook.
 *
 * In the returned button props, in addition to the required accessibility props, the following
 * handlers are included:
 * - onMouseDown
 * - onMouseUp
 * - onMouseOver
 * - onMouseLeave
 * - onKeyDown
 * - onKeyUp
 * - onBlur
 * As such, the component you pass the returned functions to may need to be configured to accept
 * these handlers (though base HTML elements will all support them). Do not pass the returned
 * handlers as a standard onClick handler to your component, as your component will not function
 * as intended.
 */
export function useButtonRole<E extends Element>(
    ref: RefObject<E>,
    {
        "aria-label": ariaLabel,
        "aria-labelledby": ariaLabelledBy,
        "aria-disabled": ariaDisabled = false,
        "aria-pressed": ariaPressed,
        onClick,
        onClickHold,
        holdDelay,
        excludedSelectors = CLICKABLE_ELEMENT_SELECTORS_EXCLUDING_BUTTON_ROLE,
        enabled = true,
    }: UseButtonRoleProps<E> = {},
): Memo<UseButtonRoleResult<E>> {
    const mouseHold = useHold<MouseEvent<E>>(onClickHold, onClick, holdDelay);
    const { cancel: mouseCancel } = mouseHold;
    const keyboardHold = useHold<KeyboardEvent<E>>(onClickHold, onClick, holdDelay);
    const { cancel: keyboardCancel } = keyboardHold;
    // if the button is disabled while hold ongoing, cancel hold
    useEffect(() => {
        if (ariaDisabled) {
            mouseCancel();
            keyboardCancel();
        }
    }, [ariaDisabled, mouseCancel, keyboardCancel]);

    const filter = useCssSelectorFilter<UIEvent<E>>(ref, excludedSelectors);

    const onMouseDown = useButtonRoleOnMouseDown(mouseHold, ariaDisabled, filter);
    const onMouseUp = useButtonRoleOnMouseUp(mouseHold, ariaDisabled, filter);
    const onMouseLeave = useButtonRoleOnMouseLeave(mouseHold, ariaDisabled);
    const onMouseOver = useButtonRoleOnMouseOver(mouseHold, ariaDisabled, filter);
    const onKeyDown = useButtonRoleOnKeyDown(keyboardHold, ariaDisabled, !!onClickHold, filter);
    const onKeyUp = useButtonRoleOnKeyUp(keyboardHold, ariaDisabled, filter);
    const onBlur = useButtonRoleOnBlur(keyboardHold, ariaDisabled);

    const documentRef = useRef(document);
    // These two event listeners are necessary so that, if the user mouse downs on the element,
    // mouse leaves the element, then mouse ups while outside the element, the hold is cancelled.
    const onDocMouseUp = useBrandedCallback<EventListener>(
        (event) => {
            if (
                ariaDisabled
                || (event.target instanceof Node && ref.current?.contains(event.target))
            ) {
                return;
            }
            mouseCancel();
        },
        [ariaDisabled, mouseCancel, ref],
    );
    useEventListener(documentRef, "mouseup", onDocMouseUp);
    const onDocDragEnd = useBrandedCallback<EventListener>(
        (event) => {
            if (
                ariaDisabled
                || (event.target instanceof Node && ref.current?.contains(event.target))
            ) {
                return;
            }
            mouseCancel();
        },
        [ariaDisabled, mouseCancel, ref],
    );
    useEventListener(documentRef, "dragend", onDocDragEnd);

    // Simulated active and hover states
    const [active, setActive] = useActive(ref, [" ", "Enter"], excludedSelectors);
    const [hover, setHover] = useHover(ref, excludedSelectors);

    const buttonProps: UseButtonRoleResult<E>["buttonProps"] = useBrandedMemo(
        () => ({
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            onMouseOver,
            onKeyDown,
            onKeyUp,
            onBlur,
            "aria-label": ariaLabel,
            "aria-labelledby": ariaLabelledBy,
            "aria-disabled": ariaDisabled,
            "aria-pressed": ariaPressed,
            tabIndex: 0,
            role: "button",
        }),
        [
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            onMouseOver,
            onKeyDown,
            onKeyUp,
            onBlur,
            ariaLabel,
            ariaLabelledBy,
            ariaDisabled,
            ariaPressed,
        ],
    );

    return useBrandedMemo(
        () => ({
            active,
            setActive,
            hover,
            setHover,
            buttonProps: enabled ? buttonProps : undefined,
        }),
        [active, setActive, hover, setHover, buttonProps, enabled],
    );
}

function useButtonRoleOnMouseDown<E extends Element>(
    { start: startHold }: UseHoldResult<MouseEvent<E>>,
    disabled: boolean,
    filter: Memo<EventFilter<UIEvent<E>>>,
): Memo<MouseEventHandler<E>> {
    const unwrapped = useBrandedCallback<MouseEventHandler<E>>(
        (event) => {
            if (event.button !== 0 || disabled) {
                return;
            }
            startHold(event);
        },
        [disabled, startHold],
    );
    return useFilteredEventHandler(unwrapped, filter);
}

function useButtonRoleOnMouseUp<E extends Element>(
    { release: releaseHold }: UseHoldResult<MouseEvent<E>>,
    disabled: boolean,
    filter: Memo<EventFilter<UIEvent<E>>>,
): Memo<MouseEventHandler<E>> {
    const unwrapped = useBrandedCallback<MouseEventHandler<E>>(
        (event) => {
            if (event.button !== 0 || disabled) {
                return;
            }
            releaseHold();
        },
        [disabled, releaseHold],
    );
    return useFilteredEventHandler(unwrapped, filter);
}

function useButtonRoleOnMouseLeave<E extends Element>(
    { suspend: suspendHold }: UseHoldResult<MouseEvent<E>>,
    disabled: boolean,
): Memo<MouseEventHandler<E>> {
    return useBrandedCallback(() => {
        if (disabled) {
            return;
        }
        suspendHold();
    }, [disabled, suspendHold]);
}

function useButtonRoleOnMouseOver<E extends Element>(
    { restart: restartHold, suspend: suspendHold }: UseHoldResult<MouseEvent<E>>,
    disabled: boolean,
    filter: Memo<EventFilter<UIEvent<E>>>,
): Memo<MouseEventHandler<E>> {
    const included = useBrandedCallback<MouseEventHandler<E>>(() => {
        if (disabled) {
            return;
        }
        restartHold();
    }, [disabled, restartHold]);
    const excluded = useBrandedCallback<MouseEventHandler<E>>(() => {
        if (disabled) {
            return;
        }
        // If the user moves the mouse to an excluded element, we want to suspend, as though
        // they had moved the mouse outside the element
        suspendHold();
    }, [disabled, suspendHold]);
    return useFilteredEventHandler([included, excluded], filter);
}

function useButtonRoleOnKeyDown<E extends Element>(
    { start: startHold, release: releaseHold }: UseHoldResult<KeyboardEvent<E>>,
    disabled: boolean,
    hasOnHold: boolean,
    filter: Memo<EventFilter<UIEvent<E>>>,
): Memo<KeyboardEventHandler<E>> {
    const unwrapped = useBrandedCallback<KeyboardEventHandler<E>>(
        (event) => {
            if (event.key === " ") {
                event.preventDefault();
            }
            if (disabled || (hasOnHold && event.repeat)) {
                // Prevent repeatedly starting the hold when event.repeat.
                // If no onHold is specified, it will repeatedly trigger the onClick, which is
                // standard behavior for buttons.
                return;
            }
            if (!hasOnHold && event.key === "Enter") {
                // If no onHold is specified, then enter fires the onClick immediately, like a standard
                // button element.
                startHold(event);
                releaseHold();
                return;
            }
            if ((hasOnHold && event.key === "Enter") || event.key === " ") {
                // If an onHold is specified, and the key is enter, OR if the key is space, we start
                // the hold.
                startHold(event);
                return;
            }
        },
        [disabled, hasOnHold, startHold, releaseHold],
    );
    return useFilteredEventHandler(unwrapped, filter);
}

function useButtonRoleOnKeyUp<E extends Element>(
    { release: releaseHold }: UseHoldResult<KeyboardEvent<E>>,
    disabled: boolean,
    filter: Memo<EventFilter<UIEvent<E>>>,
): Memo<KeyboardEventHandler<E>> {
    const unwrapped = useBrandedCallback<KeyboardEventHandler<E>>(
        (event) => {
            if (disabled || (event.key !== " " && event.key !== "Enter")) {
                return;
            }
            releaseHold();
        },
        [disabled, releaseHold],
    );
    return useFilteredEventHandler(unwrapped, filter);
}

function useButtonRoleOnBlur<E extends Element>(
    { cancel: cancelHold }: UseHoldResult<KeyboardEvent<E>>,
    disabled: boolean,
): Memo<FocusEventHandler<E>> {
    return useBrandedCallback(() => {
        if (disabled) {
            return;
        }
        cancelHold();
    }, [disabled, cancelHold]);
}
