import clsx from "clsx";
import { IconProps } from "components/Icon/IconProps";
import { Span } from "components/Text";
import { TooltipProps } from "components/Tooltip";
import { EVERCLASS, everClassProp } from "EverAttribute/EverClass";
import { everIdProp } from "EverAttribute/EverId";
import React, { cloneElement, FC, ReactElement, useId, useRef } from "react";
import "./Tag.scss";
import { EverColor } from "tokens/typescript/EverColor";
import { EverIdProp } from "util/type";

export enum TagType {
    BASIC = "basic",
    SUCCESS = "success",
    WARNING = "warning",
    CRITICAL = "critical",
    INFORMATIONAL = "informational",
    COMPLETED = "completed",
}

const TAG_TYPE_TO_TEXT_COLOR: Record<TagType, EverColor> = {
    [TagType.BASIC]: EverColor.PARCHMENT_60,
    [TagType.SUCCESS]: EverColor.GREEN_50,
    [TagType.WARNING]: EverColor.YELLOW_50,
    [TagType.CRITICAL]: EverColor.RED_50,
    [TagType.INFORMATIONAL]: EverColor.EVERBLUE_60,
    [TagType.COMPLETED]: EverColor.PARCHMENT_60,
};

export interface TagProps extends EverIdProp {
    /**
     * An optional class name to apply to the tag.
     */
    className?: string;
    /**
     * The label text of the tag, which should have at most 20 chars.
     */
    children: string;
    /**
     * The type of the tag. This determines the tag's COLOR_
     */
    type: TagType;
    /**
     * An optional icon to place to the left of the tag text. The icon color will be
     * overwritten to a color depending on the tag type.
     */
    icon?: ReactElement<IconProps>;
    /**
     * A tooltip to render on the tag.
     */
    tooltip?: ReactElement<TooltipProps>;
}

/**
 * A simple, non-interactive tag with a label, background color, and optional icon.
 */
export const Tag: FC<TagProps> = ({ everId, className, children, type, icon, tooltip }) => {
    const tagRef = useRef<HTMLSpanElement>(null);
    const tooltipId = useId();
    tooltip &&= cloneElement(tooltip, {
        id: tooltipId,
        target: tagRef,
    });
    return (
        <>
            <span
                ref={tagRef}
                className={clsx(className, "bb-tag", `bb-tag--${type}`)}
                aria-describedby={tooltip ? tooltipId : undefined}
                tabIndex={tooltip ? 0 : undefined}
                {...everIdProp(everId)}
                {...everClassProp(EVERCLASS.BB.TAG)}
            >
                {icon
                    && cloneElement(icon, {
                        className: clsx(icon.props.className, "bb-tag__icon"),
                        size: 16,
                        color: icon.props.color || TAG_TYPE_TO_TEXT_COLOR[type],
                    })}
                <Span.Overline>{children}</Span.Overline>
            </span>
            {tooltip}
        </>
    );
};
