import { CLICKABLE_ELEMENT_SELECTORS, useCssSelectorFilter } from "hooks/useCssSelectorFilter";
import { useBrandedCallback } from "hooks/useBranded";
import { useFilteredEventListener } from "hooks/useFilteredEventListener";
import { Dispatch, RefObject, SetStateAction, useState } from "react";

/**
 * A hook which allows you to imitate hover state for an element. In general, this should be
 * avoided and handled through CSS. However, sometimes it is necessary to only enable a hover state
 * when the user is hovering over the given element, and not on any child elements with their own
 * hover state (for instance, a button within a card).
 *
 * @param ref a reference to the root element to detect hover on
 * @param excludedSelectors selectors to exclude from the hover listener, passed to
 * {@link useFilteredEventListener}.
 */
export function useHover(
    ref: RefObject<Element>,
    excludedSelectors: string[] = CLICKABLE_ELEMENT_SELECTORS,
): [boolean, Dispatch<SetStateAction<boolean>>] {
    const [hover, setHover] = useState(false);
    const filter = useCssSelectorFilter(ref, excludedSelectors);
    const startHover = useBrandedCallback(() => setHover(true), []);
    const endHover = useBrandedCallback(() => setHover(false), []);
    useFilteredEventListener(ref, "mouseover", [startHover, endHover], filter);
    useFilteredEventListener(ref, "mouseleave", [endHover, startHover], filter);
    return [hover, setHover];
}
